import {
  FC,
} from 'react'
import {
  useTranslation,
} from 'react-i18next'
import {
  useParams,
} from 'react-router-dom'

import ExternalLink from '@components/ExternalLink'
import {
  useAppSelector,
} from '@root/store/Reducer'
import {
  selectOfferById,
} from '@root/services/offers/selectors'
import {
  selectInsurerById,
} from '@root/services/insurers/selectors'
import {
  useFetchMerQuery,
} from '@root/services/offers'
import {
  startsWithVowel,
} from '@root/util/lang'

const SubscriptionSuccess: FC = () => {
  const {
    offerId, estimationId, merId,
  } = useParams()
  const offer = useAppSelector(state => selectOfferById(estimationId as string, offerId as string)(state))
  const insurer = useAppSelector(state => selectInsurerById(state, offer?.insurerId ?? ''))
  const {
    data,
  } = useFetchMerQuery({
    merId: merId as string,
    offerId: offerId as string,
  })

  const {
    t,
  } = useTranslation()

  if (!data || !insurer) {
    return null
  }

  return (
    <div className='flex flex-col gap-6'>
      <ExternalLink href={data?.url}
        label={t('visitProviderSite', {
          insurer: insurer.name,
          count: startsWithVowel(insurer.name) ? 0 : 1,
          escapeValue: false,
        })}
        target='_blank'
      />
    </div>
  )
}

export default SubscriptionSuccess
