/* eslint-disable camelcase */
import {
  v4 as uuidv4,
} from 'uuid'
import {
  useNavigate,
} from 'react-router-dom'
import {
  useState,
} from 'react'
import {
  ButtonType,
} from '@selectra-it/selectra-ui'

import TextInput from '@components/form/TextInput'
import emailValidation from '@root/util/validation/email'
import {
  useCreateEstimationMutation,
} from '@root/services/offers'
import {
  useCreateUserMutation,
} from '@root/services/userService'
import Checkbox from '@components/form/Checkbox'
import Bool from '@root/util/Bool'
import {
  WizardFinished,
} from '@root/analytics/Events/WizardFinished'
import {
  useTracking,
} from '@hooks/useTracking'
import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'

const UserEmailInformation = () => {
  const {
    form,
    onBackHandler,
  } = useEstimationWizardForm({
    next: () => null,
  })
  const [createEstimation] = useCreateEstimationMutation()
  const [createUser] = useCreateUserMutation()
  const navigate = useNavigate()
  const {
    trackEvent,
  } = useTracking()

  const [loading, setIsLoading] = useState(false)

  const {
    register, handleSubmit, formState: {
      errors,
    },
  } = form

  const onSubmit = async (data: EstimationForm) => {
    const facilities = data.facilities
      ? data.facilities.map(facility => ({
        facility_id: facility,
        surface: data.facilitiesSurface?.[facility] ?? 0,
      }))
      : []

    const userId = uuidv4()
    const estimationId = uuidv4()

    setIsLoading(true)

    try {
      await createUser({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        number: data.phoneNumber?.number,
        country_code: data.phoneNumber?.country_code ?? 'FR',
        uuid: userId,
        utm_source: data.utm_source,
        utm_campaign: data.utm_campaign,
        utm_medium: data.utm_medium,
        birthDate: data.birthDate,
        address: data.userAddress?.formatted?.length
          ? {
            ...data.userAddress,
          }
          : null,
      }).unwrap()

      const {
        address, ...rest
      } = data

      await createEstimation({
        address: {
          ...address,
        },
        ...rest,
        facilities,
        accommodationCovered: Bool(data.accommodationCovered),
        contractTerminatedInLast3Years: Bool(data.contractTerminatedInLast3Years),
        capital_furniture: data.capital_furniture?.value ?? 0,
        housing_floor_level: data.housing_floor_level?.length ? data.housing_floor_level : 'basement',
        residence_type: data.residence_type?.length ? data.residence_type : 'secondary',
        userId,
        familySituation: data.familySituation ?? 'alone',
        uuid: estimationId,
        acceptPartners: data.accept_partners ?? false,
      }).unwrap()

      trackEvent(WizardFinished())

      navigate(`/offre/${estimationId}?preload=true`)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex w-full grow flex-col justify-between space-y-6'>
      <div className='grid grid-cols-1 gap-6'>
        <TextInput
          error={errors.email}
          {...register('email', {
            required: true,
            validate: emailValidation,
          })}
          label='Adresse e-mail'
        />

        <Checkbox
          {...register('terms_and_conditions', {
            required: true,
          })}
          id="terms_and_conditions"
          label="*J’accepte que mes données soient utilisées afin que des devis me soient proposés."
          error={errors.terms_and_conditions}
        />

        <Checkbox
          {...register('accept_partners')}
          id="accept_partners"
          label="J’accepte que Selectra me contacte gratuitement pour trouver l’offre d’assurance habitation la plus adaptée."
          error={errors.accept_partners}
        />
      </div>

      <NavigationButtons onBackHandler={onBackHandler} disabled={loading}
        nextVariant={ButtonType.SUCCESS}
        nextLabel="C'est bon, allons-y !"
      />
    </form>
  )
}

export default UserEmailInformation
