import {
  Datepicker,
  InputGroup,
  getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'

import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const ContractStartDateInformation = ({
  next,
}: WizardStepProps) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'Veuillez saisir votre date de debut de contrat dans champ ci-dessus afin de poursuivre. Ex : 31/01/2024',
  }, errors?.startDate?.type)

  const status = useGetInputStatus(errors?.startDate?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='mb-8 flex w-full grow flex-col justify-between space-y-6 md:mb-0'>
      <InputGroup
        errors={inputErrors}
        description={'Ex : 31/01/2024'}
      >
        <Datepicker
          label={'Date de début du contrat'}
          status={status}
          {...register('startDate', {
            required: true,
          })}
          dataCy="insurance-date"
        />

      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default ContractStartDateInformation
