import PropTypes from 'prop-types'
import React from 'react'

import Sprite from '@assets/sprite.svg'

interface IconProps {
  size?: number,
  name: string,
  className?: string,
  width?: number,
  height?: number,
}

const Icon: React.ElementType<React.SVGProps<SVGSVGElement> & IconProps> = ({
  size, name, className, width, height,
}) => {
  const widthAttribute = size || width
  const heigthAttribute = size || height

  return (
    <svg id="test" className={`${className}`}
      width={widthAttribute} height={heigthAttribute}

    >
      <use href={`${Sprite}#${name}`} />
    </svg>
  )
}

Icon.defaultProps = {
  color: 'fill-primary-50',
}

Icon.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
}

export default Icon
