import {
  func,
} from 'prop-types'
import {
  InputGroup,
  getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'

import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import useGetInputStatus from '@hooks/useGetInputStatus'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import NavigationButtons from '@components/form/NavigationButtons'

const UserPhoneInformation = ({
  next,
}) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'J’ai besoin de votre numéro de téléphone  afin de vous contacter plus tard si vous le souhaitez.',
    phoneNumber: 'Numéro de téléphone invalide',
  }, errors.phoneNumber?.number?.type)

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <InputGroup errors={inputErrors}
        description='Ex : 06 86 52 04 12'
      >
        <PhoneNumberInput
          register={register}
          name='phoneNumber.number'
          error={errors?.phoneNumber?.number}
          label='Numéro de téléphone'
          status={phoneNumberInputStatus}
        />
      </InputGroup>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

UserPhoneInformation.propTypes = {
  next: func.isRequired,
}

export default UserPhoneInformation
