import {
  FC,
  Reducer,
  useReducer,
  useState,
} from 'react'
import {
  matchPath,
} from 'react-router'
import {
  Action,
} from '@reduxjs/toolkit'
import {
  Modal,
} from '@selectra-it/selectra-ui'
import {
  useTransition, animated,
} from '@react-spring/web'

import Callback from '@components/offers/Subscription/Callback/Callback'
import Icon from '@components/Icon'
import {
  useFetchUserQuery,
} from '@root/services/userService'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'

import ContactInformation from './ContactInformation'
import {
  Step,
} from './Step'

interface ContactReducerState {
  step: Step
}

interface ContactReducerAction extends Action<Step> {
  type: Step,
}

const initialState: ContactReducerState = {
  step: Step.SUBSCRIPTION,
}

const contactReducer: Reducer<ContactReducerState, ContactReducerAction> = (state, action) => {
  return {
    step: action.type,
  }
}

interface ModalActionProps {
  onClick: () => void
}

const ModalAction: FC<ModalActionProps> = ({
  onClick,
}) => {
  return (
    <div className="flex cursor-pointer items-center justify-center gap-1 rounded-md bg-background-50 px-3 py-2 text-primary-450" onClick={onClick}>
      <label className='cursor-pointer align-middle font-trebuchet text-sm font-bold leading-tight'>01 86 76 72 36</label>
      <Icon name={'phone'} size={16}
        className='fill-primary-450'
      />
    </div>
  )
}

const ContactButton = () => {
  const [state, dispatch] = useReducer(contactReducer, initialState)
  const [isOpen, setIsOpen] = useState(false)

  const match = matchPath({
    path: '/offre/:estimationId',
  }, window.location.pathname)

  const estimationId = match?.params?.estimationId ?? ''

  const {
    data,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId)

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  const transitions = useTransition(state.step, {
    from: {
      transform: state.step === Step.SUBSCRIPTION ? 'translateX(-10%)' : 'translateX(10%)',
      opacity: 0,
    },

    enter: {
      transform: 'translateX(0%)',
      opacity: 1,
    },
    config: {
      duration: 300,
    },
  })

  return (
    <div>
      <ModalAction onClick={openModal} />
      <Modal
        onAfterClose={() => {
          dispatch({
            type: Step.SUBSCRIPTION,
          })
        }}
        setOpen={setIsOpen}
        open={isOpen}
      >
        {transitions(styles => (
          <animated.div style={styles}>
            {state.step === Step.SUBSCRIPTION && <ContactInformation dispatch={dispatch} closeModal={closeModal}/>}
            {state.step === Step.CALLBACK &&
              <Callback user={fetchUser?.data} estimationId={estimationId}
                closeModal={closeModal}
              />}
          </animated.div>
        ))}

      </Modal>
    </div>
  )
}

export default ContactButton
