import {
  Leadsource,
} from '@root/domain/Crm/Leadsource'

import {
  apiService,
} from './apiService'
import {
  ImmediateMerCallbackDate, ScheduledMerCallbackDate,
} from './offers'

interface CreateRecordMutation {
  estimation_id?: string,
  user_id: string,
  url: string,
  lead_source: Leadsource,
  offer_id?: string,
  callback_date?: ImmediateMerCallbackDate | ScheduledMerCallbackDate,
}

const crmApi = apiService.injectEndpoints({
  endpoints: builder => ({
    createRecord: builder.mutation<void, CreateRecordMutation>({
      query: ({
        ...data
      }) => ({
        url: '/api/crm/records',
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
  }),
})

export const {
  useCreateRecordMutation,
} = crmApi
