import {
  ElementType,
} from 'react'

import {
  EstimationForm,
} from '@root/domain/Model/Wizard'
import UserAgeInformation from '@components/form/EstimationForm/steps/UserAgeInformation'
import {
  FacilityType,
} from '@root/services/facilities'
import {
  HOUSE, NON_RESIDING_OWNER,
} from '@root/constants'
import UserAddressInformation from '@components/form/EstimationForm/steps/UserAddressInformation'
import {
  ResidenceType,
} from '@root/domain/Model/Estimation'
import UserInformation from '@components/form/EstimationForm/steps/UserInformation'
import ResidenceTypeInformation from '@components/form/EstimationForm/steps/ResidenceTypeInformation'
import HouseTypeInformation from '@components/form/EstimationForm/steps/HouseTypeInformation'
import HouseFloorInformation from '@components/form/EstimationForm/steps/HouseFloorInformation'
import FacilitiesInformation from '@components/form/EstimationForm/steps/FacilitiesInformation'
import OccupationStatusInformation from '@components/form/EstimationForm/steps/OccupationStatusInformation'
import HouseRoomsInformation from '@components/form/EstimationForm/steps/HouseRoomsInformation'
import HouseSurfaceInformation from '@components/form/EstimationForm/steps/HouseSurfaceInformation'
import AccomodationCoveredInformation from '@components/form/EstimationForm/steps/AccomodationCoveredInformation'
import ContractTerminatedInLast3YearsInformation from '@components/form/EstimationForm/steps/ContractTerminatedInLast3YearsInformation'
import UserEmailInformation from '@components/form/EstimationForm/steps/UserEmailInformation'
import CapitalFurnitureInformation from '@components/form/EstimationForm/steps/CapitalFurnitureInformation'
import ContractStartDateInformation from '@components/form/EstimationForm/steps/ContractStartDateInformation'
import UserPhoneInformation from '@components/form/EstimationForm/steps/UserPhoneInformation'
import AmountOfClaimsInformation from '@components/form/EstimationForm/steps/AmountOfClaimsInformation'
import FamilySituationInformation from '@components/form/EstimationForm/steps/FamilySituationInformation'
import FacilitiesSurfaceInformation from '@components/form/EstimationForm/steps/FacilitiesSurfaceInformation'
import AddressInformation from '@components/form/EstimationForm/steps/AddressInformation'

import {
  routes,
} from './wizard-routes'

export interface ShouldRenderPayload {
  facilityTypes: FacilityType[] | undefined
}

interface Step {
  path: string,
  shouldRender: (state: EstimationForm, extra: ShouldRenderPayload) => boolean,
  component: ElementType<{ next: () => void }>;
}

export const steps: Step[] = [
  {
    path: routes.HOUSE_TYPE,
    shouldRender: () => true,
    component: ({
      next,
    }) => <HouseTypeInformation next={next} />,
  },
  {
    path: routes.HOUSE_FLOOR,
    shouldRender: state => {
      return state.housing_type !== HOUSE
    },
    component: ({
      next,
    }) => <HouseFloorInformation next={next} />,
  },

  {
    path: routes.RESIDENCE_TYPE,
    shouldRender: state => state.occupation_status !== NON_RESIDING_OWNER,
    component: ({
      next,
    }) => <ResidenceTypeInformation next={next} />,
  },
  {
    path: routes.OCCUPATION_STATUS,
    shouldRender: () => true,
    component: ({
      next,
    }) => <OccupationStatusInformation next={next} />,
  },
  {
    path: routes.ADDRESS,
    shouldRender: () => true,
    component: ({
      next,
    }) => <AddressInformation next={next} />,
  },
  {
    path: routes.USER_RESIDENCE_ADDRESS,
    shouldRender: state => state.occupation_status === NON_RESIDING_OWNER || state.residence_type === ResidenceType.Secondary,
    component: ({
      next,
    }) => <UserAddressInformation next={next} />,
  },
  {
    path: routes.HOUSE_ROOMS,
    shouldRender: () => true,
    component: ({
      next,
    }) => <HouseRoomsInformation next={next} />,
  },
  {
    path: routes.HOUSE_SURFACE,
    shouldRender: () => true,
    component: ({
      next,
    }) => <HouseSurfaceInformation next={next} />,
  },
  {
    path: routes.FACILITIES,
    shouldRender: () => true,
    component: ({
      next,
    }) => <FacilitiesInformation next={next} />,
  },
  {
    path: routes.FACILITIES_SURFACE,
    shouldRender: (state, {
      facilityTypes,
    }) => {
      const inputs = facilityTypes
        ? facilityTypes
          .filter(item => {
            return item.withSurface && state.facilities && state.facilities.includes(item.value)
          })
        : null

      return inputs !== null && inputs.length > 0 && facilityTypes !== undefined && state.facilities.length > 0
    },
    component: ({
      next,
    }) => <FacilitiesSurfaceInformation next={next} />,
  },
  {
    path: routes.FAMILY_SITUATION,
    shouldRender: state => state.occupation_status !== 'non_residing_owner',
    component: ({
      next,
    }) => <FamilySituationInformation next={next} />,
  },
  {
    path: routes.ACOMMODATION_STATUS,
    shouldRender: () => true,
    component: ({
      next,
    }) => <AccomodationCoveredInformation next={next} />,
  },
  {
    path: routes.AMOUNT_OF_CLAIMS,
    shouldRender: () => true,
    component: ({
      next,
    }) => <AmountOfClaimsInformation next={next} />,
  },
  {
    path: routes.CONTRACT_TERMINATED,
    shouldRender: () => true,
    component: ({
      next,
    }) => <ContractTerminatedInLast3YearsInformation next={next} />,
  },
  {
    path: routes.FURNITURE_CAPITAL,
    shouldRender: () => true,
    component: ({
      next,
    }) => <CapitalFurnitureInformation next={next} />,
  },
  {
    path: routes.CONTRACT_START_DATE,
    shouldRender: () => true,
    component: ({
      next,
    }) => <ContractStartDateInformation next={next} />,
  },
  {
    path: routes.NAME,
    shouldRender: () => true,
    component: ({
      next,
    }) => <UserInformation next={next} />,
  },
  {
    path: routes.AGE,
    shouldRender: () => true,
    component: ({
      next,
    }) => <UserAgeInformation next={next} />,
  },
  {
    path: routes.PHONE,
    shouldRender: () => true,
    component: ({
      next,
    }) => <UserPhoneInformation next={next} />,
  },
  {
    path: routes.SUMMARY,
    shouldRender: () => true,
    component: () => <UserEmailInformation />,
  }
]

type RouteInformation = {
  previous: Step | undefined,
  next: Step | undefined,
  index: number,
}

interface RouteInformationExtraPayload {
  facilityTypes: FacilityType[] | undefined,
}

export const getRouteInformation = (currentRoute: string, state: EstimationForm, extra: RouteInformationExtraPayload): RouteInformation => {
  const currentIndex = steps.findIndex(step => step.path === currentRoute)

  const stepsAfterCurrent = steps.slice(currentIndex + 1, steps.length)

  const next = stepsAfterCurrent.find(step => step.shouldRender(state, extra))
  const previous = findPreviousStep(currentRoute, state, extra)

  return {
    index: currentIndex,
    next,
    previous,
  }
}

const findPreviousStep = (currentRoute: string, state: EstimationForm, extra: RouteInformationExtraPayload): Step | undefined => {
  const currentIndex = steps.findIndex(step => step.path === currentRoute)

  if (currentIndex <= 0) {
    return undefined // Route not found in steps
  }

  const stepsBeforeCurrentStep = steps.slice(0, currentIndex).reverse()

  return stepsBeforeCurrentStep.find(step => step.shouldRender(state, extra))
}

export const findStepIndex = (route: string) => {
  return steps.findIndex(step => step.path === route)
}

export type routeInformation = {
  next: Step | undefined,
  index: number,
}
