import {
  forwardRef,
} from 'react'
import ReactSelect, {
  SelectInstance,
  SingleValue,
} from 'react-select'
import {
  useDispatch,
} from 'react-redux'

import {
  setSort,
} from '@root/services/offers/offerSortingSlice'

interface Option {
  label: string,
  value: {
    field: string,
    direction: string,
  }
}

const OfferSorting = forwardRef<SelectInstance<Option>, unknown>((props, ref) => {
  const options: Option[] = [
    {
      label: 'Prix croissant',
      value: {
        field: 'yearly_price',
        direction: 'asc',
      },
    },
    {
      label: 'Score Selectra décroissant',
      value: {
        field: 'rating',
        direction: 'desc',
      },
    },
    {
      label: 'Franchise croissante',
      value: {
        field: 'franchise',
        direction: 'asc',
      },
    }
  ]

  const dispatch = useDispatch()

  const onChange = (option: SingleValue<Option>) => {
    if (option) {
      dispatch(setSort(option.value))
    }
  }
  return (
    <>
      <ReactSelect<Option>
        ref={ref}
        className='w-32'
        menuPlacement="auto"
        classNames={{
          menu: () => 'p-4 rounded-lg border-[#A8BACD]',
          option: state => `flex items-start px-2 self-stretch px-2 py-[6px] rounded-lg text-sm ${state.isSelected ? 'bg-primary-50 text-primary-400' : 'bg-white text-neutral-350'}`,
          singleValue: () => 'text-sm w-20',
          menuList: () => 'flex flex-col gap-2',
          control: state => `hover:border-primary-400 border-2 ${state.isFocused ? 'border-primary-400 shadow-primary-400' : 'border-neutral-150'}`,
        }}
        defaultValue={options[0]}
        styles={{
          option: () => ({}),
          menu: base => ({
            ...base,
            width: 'max-content',
            minWidth: '100%',
            right: '0px',
          }),
          singleValue: base => ({
            ...base,
            marginLeft: '',
            marginRight: '',
          }),
          menuList: () => ({}),
          control: base => {
            return ({
              ...base,
              boxShadow: '',
              borderColor: '',
              borderWidth: '',
              '&:hover': {},
            })
          },
        }}
        // name={name}
        // inputId={id}
        // inputValue={inputValue}
        // getOptionLabel={(option: Option) => option.label}
        // getOptionValue={(option: Option) => option.value}
        onChange={onChange}
        // onInputChange={onInputChange}
        options={options}
        isClearable={false}
        placeholder={''}
        isSearchable={false}
        // styles={SelectAutocompleteStyles}
        // value={defaultValue}
        components={{
          // Control,
          IndicatorSeparator: () => null,
          // DropdownIndicator,
          // Input,
          // IndicatorsContainer,
          // Placeholder: () => null,
          // SingleValue,
        }}
      />
    </>
  )
})

OfferSorting.displayName = 'OfferSorting'

export default OfferSorting
