interface FranchiseProps {
  franchise: number,
}

const Franchise = ({
  franchise,
}: FranchiseProps) => {
  return (
    <div className='flex items-start rounded bg-success-50 px-2 py-[2px]'>
      <span className="font-trebuchet text-xs font-bold text-neutral-500 md:text-sm">Franchise {franchise}€</span>
    </div>
  )
}

export default Franchise
