import Icon from '@components/Icon'
import LoadingSpinner from '@components/Loader/LoadingSpinner/LoadingSpinner'
import {
  SUCCESS,
} from '@root/constants/button'

interface CTAButtonProps {
  onClick: () => void,
  type?: 'submit' | 'button'
  label?: string
  disabled?: boolean
  loading?: boolean
  flat?: boolean
  iconRight?: string
  iconLeft?: string
  status?: unknown | unknown,
}

const CTAButton = ({
  onClick,
  type,
  label,
  disabled,
  loading,
  flat,
  iconLeft,
  iconRight,
  status,
  ...props
}: CTAButtonProps) => {
  const flatStyles = 'w-full h-10 font-bold text-base text-primary-450 bg-primary-50 hover:bg-primary-100 rounded-full text-base px-4 py-2 disabled:bg-neutral-100 disabled:text-neutral-200'
  const normalStyles = 'w-full h-10 font-bold text-base text-white bg-primary-450 hover:bg-primary-450 rounded-full text-base px-4 py-2 disabled:bg-neutral-100 disabled:text-neutral-200'
  const successStyles = 'w-full h-10 font-bold text-base text-white bg-primary-450 hover:bg-primary-450 rounded-full text-base px-4 py-2 disabled:bg-success-400 disabled:text-white'

  const getStyles = () => {
    if (status === SUCCESS) {
      return successStyles
    }

    return flat ? flatStyles : normalStyles
  }

  const styles = getStyles()

  return (
    <button type={type}
      onClick={onClick}
      className={styles}
      disabled={disabled || loading || status === SUCCESS }
      {...props}
    >
      { iconLeft &&
      <Icon name={iconLeft} size={16}
        className='inline-block fill-primary-450'
      /> }

      <span className='px-1 sm:px-3'>{label}</span>

      { iconRight &&
      <Icon name={iconRight} size={16}
        className='inline-block fill-primary-450'
      /> }
      {loading && (
        <div className='inline-block px-3'>
          <LoadingSpinner size={'sm'} className='fill-primary-400 text-gray-200' />
        </div>
      )}
    </button>
  )
}

export default CTAButton
