import PropTypes from 'prop-types'

import TextInput from '@components/form/TextInput'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import NavigationButtons from '@components/form/NavigationButtons'

const UserInformation = ({
  next,
}) => {
  const {
    form: {
      register, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <div className='grid grid-cols-1 gap-6 md:grid-cols-2'>

        <TextInput
          error={errors.firstName}
          {...register('firstName', {
            required: true,
            minLength: 3,
            maxLength: 30,
          })}
          label='Prénom'
          errorMessages={{
            required: 'J’ai besoin de votre prénom afin de démarrer la comparaison.',
          }}
        />

        <TextInput
          error={errors.lastName}
          {...register('lastName', {
            required: true,
          })}
          label='Nom'
        />

      </div>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

UserInformation.propTypes = {
  next: PropTypes.func.isRequired,
}

export default UserInformation
