import {
  FC,
} from 'react'
import {
  useParams,
} from 'react-router-dom'
import {
  Subheading,
  SubheadingType,
  Text, TextVariant,
} from '@selectra-it/selectra-ui'

import useGetUserFromEstimationIdRouteParam from '@hooks/offers/useGetUserFromEstimationIdRouteParam'
import {
  InsurerMerOption,
} from '@root/services/insurers'

import CallbackForm from '../CallbackForm'

const RequestCallbackAction: FC = () => {
  const {
    offerId, estimationId,
  } = useParams()

  const {
    fetchUser: {
      data: user,
    },
  } = useGetUserFromEstimationIdRouteParam()

  if (!user) {
    return null
  }

  return (
    <>
      <Subheading type={SubheadingType.TERTIARY}>
        Le fournisseur vous rappelle
      </Subheading>

      <Text variant={TextVariant.REGULAR}>
        Laissez vos coordonnées et le fournisseur vous rappellera pour finaliser votre souscription.
      </Text>

      <CallbackForm offerId={offerId as string} estimationId={estimationId as string}
        type={InsurerMerOption.CALLBACK_REQUEST}
        user={user}
      />
    </>
  )
}

export default RequestCallbackAction
