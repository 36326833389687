import PropTypes from 'prop-types'

const WizardProgress = ({
  currentStep, steps,
}) => {
  const percentage = Math.min(currentStep * 100 / steps, 100)
  return (
    <div className="container sticky top-12 z-10 h-1.5 w-full overflow-hidden bg-neutral-100 md:rounded-b-lg">
      <div className="h-1.5 bg-primary-400 transition-width duration-1000 ease-in-out" style={{
        width: `${percentage}%`,
      }}
      />
    </div>
  )
}

WizardProgress.propTypes = {
  currentStep: PropTypes.number.isRequired,
  steps: PropTypes.number.isRequired,
}

export default WizardProgress
