import PropTypes from 'prop-types'

const ExternalLink = ({
  href, label, target,
}) => {
  return (
    <a href={href}
      target={target}
      className="mb-2 mr-2 w-full rounded-lg bg-primary-400 px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary-450 focus:outline-none focus:ring-4 focus:ring-blue-300"
    >
      {label}
    </a>
  )
}

ExternalLink.propTypes = {
  href: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  target: PropTypes.oneOf([
    '_blank',
    '_parent',
    '_self',
    '_top'
  ]),
}

export default ExternalLink
