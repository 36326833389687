import {
  Guarantee, Pack,
} from '@root/domain/Model/Offer'

import OptionListItem from './OptionListItem'

interface OptionListProps {
  items: (Guarantee|Pack)[]
}

const OptionList = ({
  items,
}: OptionListProps) => {
  return (
    <div className='flex flex-col items-start gap-2 md:gap-4'>
      <div className='flex items-start justify-between gap-3 self-stretch'>
        <h3 className='font-trebuchet text-base font-bold text-neutral-500 md:text-lg'>Garanties optionnelles</h3>
      </div>

      {items.map(item => {
        return <OptionListItem item={item} key={item.id} />
      })}
    </div>
  )
}
export default OptionList
