import {
  Controller,
} from 'react-hook-form'
import {
  InputGroup, getErrorMessages, ErrorMessages,
} from '@selectra-it/selectra-ui'
import {
  FC,
} from 'react'

import Map from '@components/maps/Map'
import useEstimationWizardForm from '@hooks/estimation/useEstimationWizardForm'
import FullAddress from '@components/form/FullAddress'
import NavigationButtons from '@components/form/NavigationButtons'

import {
  WizardStepProps,
} from '../EstimationFormWizard'

const AddressInformation: FC<WizardStepProps> = ({
  next,
}) => {
  const {
    form: {
      watch, control, formState: {
        errors,
      },
    },
    onSubmitHandler, onBackHandler,
  } = useEstimationWizardForm({
    next,
  })

  const address = watch('address')

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'Merci de sélectionner une adresse proposée.',
  }, errors?.address?.type)

  return (
    <form onSubmit={onSubmitHandler()} className='flex w-full grow flex-col justify-between space-y-6'>
      <div className='flex flex-col gap-6'>
        <InputGroup
          description={'94 Rue Pierre Corneille, 69003 Lyon, France'}
          errors={inputErrors}
        >
          <Controller name='address' control={control}
            rules={{
              required: true,
            }} render={renderParams => {
              const {
                field: {
                  value,
                  ref,
                  name,
                  onChange,
                },
              } = renderParams

              return (
                <FullAddress
                  id='address'
                  value={value}
                  ref={ref}
                  name={name}
                  onChange={onChange}
                />
              )
            }}
          />

        </InputGroup>

        {address?.geometry?.location?.lat && <Map {...address} zoom={16} />}

      </div>

      <NavigationButtons onBackHandler={onBackHandler} />
    </form>
  )
}

export default AddressInformation
