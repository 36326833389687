import {
  ChangeEvent,
  MouseEventHandler, forwardRef,
} from 'react'

import Icon from '@components/Icon'

export interface ToggleInputOption {
  value: HTMLInputElement['value'],
  label: string,
  icon: string,
  onClick?: MouseEventHandler<HTMLInputElement>
  disabled?: boolean,
}

interface ToggleInputProps {
  option: ToggleInputOption,
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string,
}

const ToggleInput = forwardRef<HTMLInputElement, ToggleInputProps>(({
  option: {
    value,
    disabled = false,
    label,
    onClick,
    icon,
  },
  onChange,
  onBlur,
  name,
}, ref) => {
  return (
    <div className='flex' onClick={onClick}>
      <input type='radio'
        ref={ref}
        id={value}
        name={name}
        value={value}
        className='peer sr-only'
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
      />
      <label htmlFor={value} className='flex items-center justify-center gap-2 rounded-md bg-white fill-neutral-350 p-2  text-neutral-350 peer-checked:bg-primary-50 peer-checked:fill-primary-500 peer-checked:text-primary-500'>
        <Icon name={icon} size={18} />
        <span>{label}</span>
      </label>
    </div>
  )
})

ToggleInput.displayName = 'ToggleInput'

export default ToggleInput
