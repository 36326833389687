import Icon from '@components/Icon'
import {
  errorMessagesType, errorType,
} from '@root/types'

const errors = {
  minLength: 'La valeur est trop courte',
  required: 'Le champ est obligatoire',
  validate: 'Format non valide',
  phoneFormat: 'Numéro de téléphone invalide',
}

const InputErrorMessage = ({
  error,
  errorMessages,
}) => {
  const merged = {
    ...errors,
    ...errorMessages,
  }

  const {
    type,
  } = error
  const message = error.message.length ? error.message : merged[type]

  return (
    <div className='flex  gap-2 py-2 text-left text-sm text-neutral-450'>
      <div className='size-6'>
        <Icon name='cancel circle' className='inline-block fill-danger-400'
          size={24}
        />
      </div>

      <div className='items-center'>
        {type === 'address' &&
        <label>Si vous ne trouvez pas votre adresse, appelez le <a className="font-bold text-primary-450 hover:text-primary-500" href="tel:09 64 52 42 12">09 64 52 42 12</a> pour qu’un expert s’occupe de vous.</label>}

        {type === 'addressFormatted' &&
        <label>J’ai besoin de votre adresse afin d’établir vos devis.</label>}
        <label dangerouslySetInnerHTML={{
          __html: message,
        }}
        />
      </div>
    </div>
  )
}

InputErrorMessage.propTypes = {
  error: errorType,
  errorMessages: errorMessagesType,
}

export default InputErrorMessage
