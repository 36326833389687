import {
  FC,
} from 'react'
import {
  Link,
} from 'react-router-dom'
import {
  Button, ButtonSize, ButtonType,
} from '@selectra-it/selectra-ui'

import {
  Offer,
} from '@root/domain/Model/Offer'

interface SubscriptionButtonProps {
  offer: Offer
}

const SubscriptionButton: FC<SubscriptionButtonProps> = ({
  offer,
}) => {
  return (
    <Link to={`/offre/${offer.estimationId}/${offer.id}/mer`}>
      <Button
        type='submit'
        label="Continuer"
        variant={ButtonType.PRIMARY}
        size={ButtonSize.MEDIUM}
        pill
      />
    </Link>
  )
}

export default SubscriptionButton
