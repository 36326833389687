import {
  arrayOf,
  number, oneOf, oneOfType, shape, string,
} from 'prop-types'
import {
  validate,
} from 'uuid'

const valueType = shape({
  value: oneOfType([number, string]),
})

const perilType = shape({
  id: valueType,
  description: valueType,
})

const coverageType = shape({
  id: valueType,
  key: valueType,
})

const guaranteeType = shape({
  id: valueType,
  franchise: valueType,
  premium: valueType,
  perils: arrayOf(perilType),
  coverage: coverageType,
})

const offerType = shape({
  id: valueType,
  estimationId: valueType,
  insurerId: valueType,
  title: valueType,
  externalId: valueType,
  price: valueType,
  monthlyPrice: valueType,
  closingType: oneOf(['LINK_API', 'DIRECT_URL']),
  guarantees: arrayOf(guaranteeType),
  url: valueType,
})

const userNameType = shape({
  firstName: valueType,
  lastName: valueType,
})

const userType = shape({
  id: valueType,
  name: userNameType,
  email: valueType,
  phoneNumber: shape({
    number: string,
    country_code: string,
  }),
})

const documentType = shape({
  id: valueType,
  type: string,
  name: valueType,
  offerId: valueType,
})

const uuidType = (props, propName, componentName) => {
  if (!validate(props[propName])) {
    return new Error(`Invalid prop ${propName} supplied to ${componentName}. Not a valid UUID`)
  }
}

const errorType = shape({
  type: oneOf([
    'minLength',
    'maxLength',
    'required',
    'default',
    'validate',
    'address',
    'addressFormatted',
    'phoneFormat',
    'min'
  ]),
  message: string,
})

const errorMessagesType = shape({
  minLength: string,
  required: string,
  validate: string,
  maxLength: string,
})

const addressType = shape({
  formatted: string,
  postal_code: string,
  country: string,
  city: string,
  street_name: string,
  street_number: string,
  geometry: shape({
    location: shape({
      lat: number,
      lng: number,
    }),
    viewport: shape({
      south: number,
      west: number,
      north: number,
      east: number,
    }),
  }),
})

export {
  offerType,
  guaranteeType,
  userType,
  uuidType,
  documentType,
  errorMessagesType,
  errorType,
  addressType
}
