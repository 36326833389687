import PropTypes from 'prop-types'
import {
  useEffect,
} from 'react'
import {
  useSpring, animated,
} from '@react-spring/web'

import OfferListItem from '@components/offers/OfferListItem'
import {
  useFetchUserQuery,
} from '@root/services/userService'
import OffersMessage from '@components/offers/OffersMessage/OffersMessage'
import OfferMessageMinimumLegalModal from '@components/offers/OffersMessage/OfferMessageMinimumLegalModal'
import OfferSorting from '@components/offers/OffersSort'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'
import CallbackBanner from '@components/offers/CallbackBanner'
import OfferViewToggle from '@components/offers/OfferViewToggle'

interface OffersProps {
  estimationId: string
}

const Offers = ({
  estimationId,
}: OffersProps) => {
  const {
    data, refetch, error,
    isSuccess,
  } = useGetOffersByEstimationId(estimationId)

  const fetchUser = useFetchUserQuery(data?.estimation?.userId ?? '', {
    skip: !isSuccess,
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (data?.estimation?.status === 'complete' || error) {
        clearInterval(interval)
        return
      }

      refetch()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [data, refetch, error])

  const items = data?.items && data.items.map(item =>
    <OfferListItem offer={item} key={item.id} />
  )

  const withBanner = items ? [...items.slice(0, 4), <CallbackBanner key='callback-banner' />, ...items.slice(4, items.length)] : []

  const [offerListStyle] = useSpring({
    from: {
      transform: 'translateY(10%)',
      opacity: 0,
    },
    to: {
      transform: fetchUser.isSuccess && isSuccess ? 'translateY(0%)' : 'translateY(10%)',
      opacity: fetchUser.isSuccess && isSuccess ? 1 : 0,
    },
  }, [fetchUser, isSuccess])

  return (
    <div className='container flex h-full flex-col gap-6 md:gap-12'>
      <OfferMessageMinimumLegalModal key='customMessage'/>
      { fetchUser.isSuccess && isSuccess &&
        <OffersMessage items={data?.items?.length} name={fetchUser.data.firstName}
          status={data?.estimation?.status}
        />
      }

      <animated.div style={offerListStyle} className='flex w-full flex-col gap-4 md:gap-12'
        role='list'
      >
        <div className='flex flex-col items-start gap-6 bg-white p-6 md:rounded-b-lg md:rounded-t-2xl'>
          <div className='flex items-center justify-between gap-6 self-stretch '>
            <OfferViewToggle />
            <OfferSorting />
          </div>
        </div>
          {...withBanner}
      </animated.div>
    </div>
  )
}

Offers.propTypes = {
  estimationId: PropTypes.string,
}

export default Offers
