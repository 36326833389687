import * as Sentry from '@sentry/react'
import {
  Controller,
  useForm,
} from 'react-hook-form'
import {
  v4,
} from 'uuid'
import {
  useState,
} from 'react'
import {
  InputGroup,
  getErrorMessages,
  ErrorMessages,
  TextInput,
  Subheading,
  SubheadingType,
  Button,
  ButtonType,
  ButtonSize,
} from '@selectra-it/selectra-ui'

import {
  useCreateRecordMutation,
} from '@root/services/crmService'
import {
  useCreateUserMutation,
  useUpdateUserMutation,
} from '@root/services/userService'
import {
  User,
} from '@root/domain/Model/User'
import {
  Leadsource,
} from '@root/domain/Crm/Leadsource'
import PhoneNumberInput from '@components/form/PhoneNumberInput'
import useGetInputStatus from '@hooks/useGetInputStatus'
import CallbackDateInput from '@components/form/crm/CallbackDateInput'
import CallbackTimeInput, {
  CallbackTime,
} from '@components/form/crm/CallbackTimeInput'
import LoadingSpinner from '@components/Loader/LoadingSpinner/LoadingSpinner'
import {
  CallbackDate,
  callbackDateNow,
} from '@root/domain/Model/OfferMer'

import {
  formatCallbackDateTime,
} from '../v2/CallbackForm'

interface CallbackProps {
  user?: User,
  offerId?: string,
  estimationId?: string,
  closeModal: () => void
}

interface CallbackForm {
  firstName: string,
  lastName: string,
  callbackTime: CallbackTime | null,
  callbackDate: CallbackDate | null,
  phoneNumber: Record<string, string>
}

const Callback = ({
  user,
  offerId,
  estimationId,
}: CallbackProps) => {
  const [loading, setLoading] = useState(false)
  const [buttonMessage, setButtonMessage] = useState('Rappelez-moi')
  const [variant, setVariant] = useState<ButtonType>(ButtonType.PRIMARY)

  const form = useForm<CallbackForm>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: undefined,
    context: undefined,
    criteriaMode: 'firstError',
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
    delayError: undefined,
    defaultValues: {
      callbackTime: null,
      callbackDate: callbackDateNow,
      phoneNumber: {
        number: user?.phoneNumberNational,
        country_code: user?.phoneCountryCode ?? 'FR',
      },
      firstName: user?.firstName,
      lastName: user?.lastName,
    },
  })

  const {
    control, handleSubmit, register, watch, formState: {
      errors,
    },
  } = form

  const createOrUpdateUser = async (data: CallbackForm & {uuid: string}) => {
    if (user?.id) {
      await updateUser({
        uuid: data.uuid,
        firstName: data.firstName,
        number: data.phoneNumber.number ?? user.phoneNumber,
        country_code: data.phoneNumber.country_code ?? user.phoneCountryCode,
      }).unwrap()
    } else {
      await createUser({
        uuid: data.uuid,
        firstName: data.firstName,
        number: data.phoneNumber.number,
        country_code: data.phoneNumber.country_code,
      }).unwrap()
    }
  }

  const [createUser] = useCreateUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [createRecord] = useCreateRecordMutation()

  const callbackDate = watch('callbackDate')

  const onSubmit = async (data: CallbackForm) => {
    const userId = user?.id ?? v4()

    try {
      setLoading(true)

      await createOrUpdateUser({
        ...data,
        uuid: userId,
      })

      await createRecord({
        estimation_id: estimationId,
        user_id: userId,
        offer_id: offerId,
        url: window.location.href,
        callback_date: formatCallbackDateTime(data.callbackDate, data.callbackTime),
        lead_source: Leadsource.COMPARATOR_HELP,
      }).unwrap()

      setButtonMessage('Rappel programmé !')
      setVariant(ButtonType.SUCCESS)
    } catch (e) {
      Sentry.captureException(e)
    } finally {
      setLoading(false)
    }
  }

  const firstNameInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.firstName?.type)

  const lastNameInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.lastName?.type)

  const inputErrors = getErrorMessages({
    ...ErrorMessages,
    required: 'J’ai besoin de votre numéro de téléphone  afin de vous contacter plus tard si vous le souhaitez.',
    phoneNumber: 'Numéro de téléphone invalide',
  }, errors.phoneNumber?.number?.type)

  const dateInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callbackDate?.type)

  const dateTimeInputErrors = getErrorMessages({
    ...ErrorMessages,
  }, errors.callbackTime?.type)

  const phoneNumberInputStatus = useGetInputStatus(errors.phoneNumber?.number?.type)
  const firstNameInputStatus = useGetInputStatus(errors.firstName?.type)
  const lastNameInputStatus = useGetInputStatus(errors.lastName?.type)

  return (
    <div className='flex flex-col gap-6'>
      <Subheading type={SubheadingType.TERTIARY}>
        Rappel gratuit d&apos;un expert
      </Subheading>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6'>
          { !user?.firstName && <InputGroup errors={firstNameInputErrors}>
            <TextInput
              status={firstNameInputStatus}
              {...register('firstName', {
                required: true,
                minLength: 3,
                maxLength: 30,
              })}
              id='firstNameCallback'
              label='Prénom'
            />
          </InputGroup> }

          { !user?.lastName && <InputGroup errors={lastNameInputErrors}>
            <TextInput
              status={lastNameInputStatus}
              {...register('lastName', {
                required: true,
                minLength: 3,
                maxLength: 30,
              })}
              id='lastNameCallback'
              label='Nom'
            />
          </InputGroup> }

          <InputGroup errors={inputErrors}>
            <PhoneNumberInput<CallbackForm>
              label='Numéro de téléphone'
              name='phoneNumber.number'
              register={register}
              status={phoneNumberInputStatus}
            />
          </InputGroup>

          <InputGroup errors={dateInputErrors}>
            <Controller
              name='callbackDate'
              control={control}
              rules={{
                required: true,
              }}
              render={renderParams => {
                const {
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                  },
                } = renderParams

                return (
                  <CallbackDateInput value={value} ref={ref}
                    name={name} onChange={onChange}
                    error={errors.callbackDate?.type}
                  />
                )
              }}
            />
          </InputGroup>

          { callbackDate && callbackDate.id !== 'now' &&
          <InputGroup errors={dateTimeInputErrors}>
            <Controller
              name='callbackTime'
              control={control}
              rules={{
                required: true,
              }}
              render={renderParams => {
                const {
                  field: {
                    value,
                    ref,
                    name,
                    onChange,
                  },
                } = renderParams

                return (
                  <CallbackTimeInput value={value} ref={ref}
                    name={name} onChange={onChange}
                    error={errors.callbackTime?.type}
                  />
                )
              }}
            />
          </InputGroup>
          }

          <Button
            size={ButtonSize.MEDIUM}
            pill
            variant={variant}
            type='submit'
            disabled={loading}
            label={buttonMessage}
            iconRight={loading ? () => <LoadingSpinner size={'sm'} className='fill-primary-400 text-gray-200' /> : () => <></>}
          />
        </div>
      </form>
    </div>
  )
}

export default Callback
