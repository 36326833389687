import {
  forwardRef,
} from 'react'
import {
  ChangeHandler,
  FieldError,
} from 'react-hook-form'

import InputErrorMessage from './InputErrorMessage'

interface TextInputProps {
  error?: FieldError,
  label: string,
  onChange: ChangeHandler,
  onBlur: ChangeHandler,
  name: string,
  id?: string,
  errorMessages?: Record<string, string>
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(({
  label,
  onChange,
  onBlur,
  name,
  id,
  error,
  errorMessages,
}: TextInputProps, ref) => {
  const borderColor = error ? 'border-danger-400' : 'border-neutral-150'

  return (
    <div className="w-full">
      <div className="relative">
        <input
          type="text"
          id={id ?? name}
          placeholder=' '
          ref={ref}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          className={`${borderColor} peer block h-14 w-full appearance-none rounded-lg border-2 bg-white px-4 pb-3.5 pt-7 text-base text-neutral-500 focus:border-primary-400 focus:outline-none focus:ring-0`}
        />
        <label htmlFor={id ?? name} className='absolute left-4 top-5 origin-[0] -translate-y-3 text-xs  text-neutral-400 duration-300 peer-placeholder-shown:translate-y-0 peer-placeholder-shown:text-base peer-focus:-translate-y-3 peer-focus:text-xs'>{label}</label>
      </div>
      { error && <InputErrorMessage error={error} errorMessages={errorMessages} />}
    </div>
  )
})

TextInput.displayName = 'TextInput'

export default TextInput
