import {
  useEffect,
  useState,
} from 'react'
import {
  useForm,
} from 'react-hook-form'

import Toggle from '@components/form/Toggle'
import Tooltip from '@components/ui/Tooltip'
import {
  ToggleInputOption,
} from '@components/form/Toggle/ToggleInput'

interface OfferViewToggleState {
  view: 'list' | 'grid'
}

const OfferViewToggle = () => {
  const [open, setOpen] = useState<boolean>(false)
  const {
    register,
  } = useForm<OfferViewToggleState>({
    defaultValues: {
      view: 'list',
    },
  })

  const options: ToggleInputOption[] = [
    {
      value: 'list',
      label: 'Liste',
      icon: 'list',
    },
    {
      value: 'table',
      label: 'Tableau',
      icon: 'shelf_position',
      onClick: () => {
        setOpen(true)
      },
      disabled: true,
    }
  ]

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(false)
    }, 5000)

    return () => clearTimeout(timer)
  }, [open])

  return (
    <div className='relative'>
      <Toggle options={options} {...register('view')}/>
      <Tooltip open={open} />
    </div>
  )
}

export default OfferViewToggle
