import {
  useState,
} from 'react'

import Modal from '@components/Modal/Modal'
import {
  Size,
} from '@components/Modal/Size'
import Icon from '@components/Icon'
import Donut from '@components/Chart/Donut'
import {
  displayRating,
} from '@root/util/rating'
import useGetOffersByEstimationId from '@hooks/offers/useGetOffersByEstimationId'

import DocumentLegendItem from './DonutLegendItem'
import RatingModalTrigger from './RatingModalTrigger'

const donutData = [
  {
    name: 'Cluster 2',
    value: 52,
    color: '#7F8184',
  },
  {
    name: 'Cluster 3',
    value: 25,
    color: '#046813',
  },
  {
    name: 'Cluster 1',
    value: 23,
    color: '#015AA3',
  }
]

interface RatingModalProps {
  rank: number,
  rating: number,
  estimationId: string,
}

const RatingModal = ({
  rank,
  rating,
  estimationId,
}: RatingModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const {
    data,
  } = useGetOffersByEstimationId(estimationId)

  const closeModal = () => {
    setIsOpen(false)
  }

  const openModal = () => {
    setIsOpen(true)
  }

  return (
    <Modal
      size={Size.LG}
      isOpen={isOpen}
      onRequestClose={closeModal}
      action={<RatingModalTrigger onClick={openModal} rating={rating} />}
    >
      <div className='flex max-h-[80vh] flex-col gap-4'>
        <div className='flex justify-between'>
          <div className='flex items-center gap-1 text-base font-bold text-neutral-500'>
            <Icon name='verified-user' size={24}
              className='inline-block fill-success-350'
            />
            Système de notation Selectra
          </div>
          <div className='cursor-pointer' onClick={closeModal}>
            <Icon name='cross' size={24}
              className='fill-neutral-300'
            />
          </div>
        </div>
        <div className='flex flex-col gap-4 overflow-y-auto px-1 text-base text-neutral-450'>
          <h2 className='font-bold'>Qu’est-ce que le score Selectra ?</h2>
          <p>Il s’agit d’une note sur 5 attribuée à chaque offre. Elle guide les utilisateurs qui souhaitent être regardant sur la qualité de leur assurance.</p>
          <h2 className='font-bold'>Pourquoi un score Selectra ?</h2>
          <p>Trop souvent, le choix d’un contrat d’assurance est fait uniquement en fonction du prix. Cette situation amène beaucoup d’assurés qui subissent des sinistres à être peu remboursés et à payer des frais élevés.</p>
          <h2 className='font-bold'>Quelles sont les catégories notées ?</h2>
          <div className='flex justify-between gap-4 rounded border border-dotted px-1 py-4 md:justify-normal md:gap-10 md:px-6'>
            <div className='h-24'>
              <Donut data={donutData} />
            </div>
            <div className='flex flex-col justify-between'>
              <DocumentLegendItem color='bg-primary-450' label='Simplicité d’utilisation'
                percentage={23}
              />
              <DocumentLegendItem color='bg-neutral-400' label='Qualité du contrat'
                percentage={52}
              />
              <DocumentLegendItem color='bg-success-400' label='Qualité service client'
                percentage={25}
              />
            </div>
          </div>
          <div>
            <p className='text-base font-bold text-neutral-600'>Cette offre a été notée {displayRating(rating)}/5, et se situe à la {rank}<sup>e</sup> place du classement sur un total de {data?.items?.length} devis.</p>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default RatingModal
