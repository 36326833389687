import './App.css'

import {
  Outlet,
} from 'react-router-dom'
import {
  Default,
} from '@selectra-it/selectra-ui'

import Header from '@components/Header/Header'
import useScrollToTop from '@hooks/useScrollToTop'
import useRemoveTrailingSlash from '@hooks/useRemoveTrailingSlash'

import {
  usePersistUtmParameters,
} from './hooks/usePersistUtmParameters'

function App () {
  useScrollToTop()
  useRemoveTrailingSlash()
  usePersistUtmParameters()

  return (
    <div className="overflow-x-hidden">
      <Default header={<Header />}>
        <div className='mb-6 md:mb-24'>
          <Outlet />
        </div>
      </Default>
    </div>
  )
}

export default App
