interface PriceProps {
  price: number,
}

const Price = ({
  price,
}: PriceProps) => {
  const euros = Math.floor(price / 100)
  const cents = (price % 100).toString().padStart(2, '0')

  return (
    <div className='flex items-center justify-end text-success-400'>
      <span className='block text-[40px] font-bold '>{euros}</span>
      <div className='flex flex-col items-start'>
        <span className='text-[22px] font-bold leading-[100%]'>€{cents}</span>
        <span className='text-base leading-[100%] '>/an</span>
      </div>
    </div>
  )
}

export default Price
