import {
  ChangeEvent,
  forwardRef,
} from 'react'

import ToggleInput, {
  ToggleInputOption,
} from './ToggleInput'

interface ToggleProps {
  options: ToggleInputOption[],
  onBlur: (e: ChangeEvent<HTMLInputElement>) => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string,
}

const Toggle = forwardRef<HTMLInputElement, ToggleProps>(({
  options,
  onBlur,
  onChange,
  name,
}, ref) => {
  return (
    <div className="flex flex-col items-start gap-2">
      <div className="flex flex-col items-start">
        <div className='flex items-center justify-center gap-2 rounded-lg border border-neutral-150 p-[3px]'>
          {options.map(option => {
            return (
              <ToggleInput option={option} key={option.value}
                ref={ref} onChange={onChange}
                onBlur={onBlur}
                name={name}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
})

Toggle.displayName = 'Toggle'

export default Toggle
