import {
  BEST_PRICE, BEST_RATING, FREE_TRIAL_1_MONTH,
} from '@root/services/offers'

export default {
  surface_dependencies_question_one: 'Quelle est la surface de cette pièce supplémentaire ?',
  surface_dependencies_question_other: 'Quelles sont les surfaces de ces pièces supplémentaires ?',
  application_fee_zero: 'Aucun',
  application_fee_other: '{{count}}€ (inclus dans le prix affiché)',
  customer_service: {
    online_chat: 'Chat en ligne',
    phone: 'Téléphone',
  },
  offer_subscription_title: 'Souscrire à l\'offre {{name}}',
  facility_surface: {
    'label-male-startsWithVowel_false': 'Surface du <span class="lowercase">{{type}}</span> en m&sup2;',
    'label-female-startsWithVowel_false': 'Surface de la <span class="lowercase">{{type}}</span> en m&sup2;',
    'label-male-startsWithVowel_true': 'Surface du <span class="lowercase">{{type}}</span> en m&sup2;',
    'label-female-startsWithVowel_true': 'Surface de l\'<span class="lowercase">{{type}}</span> en m&sup2;',
  },
  validation: {
    surface: 'La surface de votre logement est en dehors des limites définies par notre comparateur, veuillez nous contacter au <a class="text-bold text-primary-450 hover:text-primary-500" href="tel:+330964562312">09 64 56 23 12</a> pour un devis sur mesure.',
  },
  visitProviderSite_zero: "Aller sur le site d'{{insurer}}",
  visitProviderSite_one: 'Aller sur le site de {{insurer}}',
  next: 'Suivant',
  next_zero: 'Aucune',
  offer: {
    tags: {
      [BEST_PRICE]: 'Le moins cher',
      [BEST_RATING]: 'Meilleure couverture',
      [FREE_TRIAL_1_MONTH]: '1 mois offert !',
    },
  },
  capitalFurniture: {
    information: 'Exclure vos bijoux et inclure vos biens mobiliers tels que les meubles, l\'électroménager, les vêtements, etc.',
    information_flat: 'Pour un appartement de {{surface}} m&sup2, le capital mobilier recommandé afin de bien couvrir les biens que vous possédez est de <span class="font-bold">{{value}}€</span>',
    information_house: 'Pour une maison de {{surface}} m&sup2, le capital mobilier recommandé afin de bien couvrir les biens que vous possédez est de <span class="font-bold">{{value}}€</span>',
  },
  input: {
    phoneNumber: {
      label: 'Numéro de téléphone',
    },
  },
}
