import Icon from '@components/Icon'

interface TooltipProps {
  open: boolean,
}

const Tooltip = ({
  open,
}: TooltipProps) => {
  if (!open) {
    return <></>
  }

  return (
    <div className='absolute top-16 flex w-80 flex-col items-start justify-end rounded-lg border border-neutral-100 bg-[#F1FFFF] p-3'>
      <label className='flex items-center justify-center '>
        <p className='text-left text-sm text-neutral-350'>
          <Icon name="circle-info" size={16}
            className='mr-2 inline-block fill-success-400'
          />Notre fonctionnalité &quot;Tableau&quot; est en cours de développement. Bientôt disponible !</p>
      </label>
    </div>
  )
}

export default Tooltip
