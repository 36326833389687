import {
  Link,
} from 'react-router-dom'

import {
  ReactComponent as Logo,
} from '@assets/selectra-logo.svg'

import ContactButton from './ContactButton'

const Header = () => {
  return (
    <nav className="sticky top-0 z-10 h-12 w-full border-gray-200 bg-white">
      <div className='container'>
        <div className="flex h-12 justify-between px-[15px] py-2 align-middle md:px-0">
          <div className='flex gap-4'>
            <Link to="/" className="flex items-center"
              aria-label="Link to start of onboarding"
            >
              <Logo className="h-4 fill-primary-400" />
            </Link>
          </div>
          <ContactButton />
        </div>
      </div>
    </nav>
  )
}

export default Header
